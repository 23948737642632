import Link from 'next/link'
import Logo from 'qtc-new/components/Logo'
import { THEME } from '@qtc-repo/common/contexts/ThemeContext'
import { useSystemValues } from '@qtc-repo/common/contexts/SharedContext'
import LinkText from '../../LinkText/LinkText'
import { PDFTYPE } from '@qtc-repo/common/constants'

const Footer = ({ theme }: { theme: THEME }) => {
  const { openPdf } = useSystemValues()
  return (
    <footer className="relative z-20 bg-gray-50 px-4 py-16 dark:bg-gray-800">
      <div className="mx-auto max-w-screen-xl text-center">
        <div className="mx-auto w-max">
          <Logo dark={theme === THEME.LIGHT} />
        </div>
        <div className="mt-6 flex flex-col items-center justify-start gap-8 text-center">
          <p className="mx-auto max-w-2xl text-gray-500 dark:text-gray-400">
            Quick Tax Claims Limited | Company Number: 14377745 | Address: International House, 61 Mosley Street,
            Manchester, M2 3HZ | info@quicktaxclaims.co.uk{' '}
          </p>
          <p className="mx-auto max-w-2xl text-gray-500 dark:text-gray-400">
            Quick Tax Claims Limited provides services in line with its{' '}
            <LinkText hover text={'Terms of Engagement'} onClick={() => openPdf(PDFTYPE.TERMS_OF_ENGAGEMENT)} /> and{' '}
            <LinkText hover={true} text={'Privacy Policy'} onClick={() => openPdf(PDFTYPE.PRIVACY_POLICY)} />
          </p>
          <span className="w-full text-sm text-gray-500 dark:text-gray-400 sm:text-center">
            © 2023&nbsp;
            <Link href="/" className="hover:underline">
              Quick Tax Claims Limited
            </Link>
            . All Rights Reserved.
          </span>
        </div>
      </div>
    </footer>
  )
}

export default Footer
