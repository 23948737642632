import Link from 'next/link'
import React from 'react'
import Img from '@qtc-repo/assets/images/favicon.png'
import ImgDark from '@qtc-repo/assets/images/favicon-dark.png'
import Image from 'next/image'
import { useSystemValues } from '@/contexts/ValueContext'
import { STEP } from '@qtc-repo/common/constants'

type Props = {
  dark?: boolean
  preventDefault?: boolean
}

const Logo = ({ dark, preventDefault }: Props) => {
  const { step, setStep } = useSystemValues()
  return (
    <Link
      href="/"
      onClick={e => {
        if (preventDefault) {
          e.preventDefault()
          return
        }
        if (step === STEP.ALL_DONE) return
        setStep(STEP.INIT)
      }}
      className=" flex items-center justify-start gap-1 text-2xl font-semibold text-gray-900 dark:text-white   sm:gap-2"
    >
      <Image src={dark ? ImgDark : Img} priority className="w-[220px] sm:w-[300px] " alt="Logo" />
    </Link>
  )
}

export default Logo
